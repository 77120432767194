import { Flex, Link, Picto } from '@stars-ecom/shared-atoms-ui'
import {
  CartContext,
  CheckoutShippingSummary,
  DataLayerUtils,
  ShippingContextProvider,
  ShippingModeList,
  UserContext,
  WebsiteContext
} from '@stars-ecom/shared-organisms-ui'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

const ShippingPage = () => {
  const [shipping, _setShipping] = useState({ step: 1, isLoading: true })
  const currentCart = useContext(CartContext)
  const currentUser = useContext(UserContext)
  const setShipping = (v) => {
    _setShipping(v)
  }

  const websiteContext = useContext(WebsiteContext)

  useEffect(() => {
    if (currentCart?.id) {
      DataLayerUtils.addShippingEvent({
        order: currentCart,
        command: 'checkoutStep',
        customer: currentUser,
        checkoutStepNumber: 3,
        checkoutStepName: 'Livraison'
      })
    }
  }, [currentCart?.id])

  return (
    <Flex direction="column" align="center" justify="center" w="100%">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Livraison" />
        <title>Livraison</title>
      </Helmet>
      <ShippingContextProvider
        value={{
          shipping,
          setShipping
        }}>
        <Flex
          direction="column"
          width={{ base: '100%', md: '1000px' }}
          maxWidth={{ base: '420px', md: '1000px' }}
          paddingLeft={{ base: '10px', md: '15px' }}
          paddingRight={{ base: '10px', md: '15px' }}
          justify="space-between"
          align="flex-start"
          style={{ marginTop: '10px' }}>
          <Flex
            direction="row"
            align="center"
            fontFamily="PT Sans, arial, sans-serif"
            fontSize="14px"
            mb={{ base: '30px', md: '20px' }}
            mt={{ base: '20px', md: '0' }}>
            <Picto
              icon="chevronLeft"
              width="11px"
              height="11px"
              color={websiteContext?.mainColor}
            />
            <Link
              to="/checkout/onepage"
              style={{ textDecoration: 'underline', marginLeft: '5px', cursor: 'pointer' }}>
              Retour au panier
            </Link>
          </Flex>

          <Flex direction={{ base: 'column-reverse', md: 'row' }} w="100%">
            <ShippingModeList />
            <CheckoutShippingSummary />
          </Flex>
        </Flex>
      </ShippingContextProvider>
    </Flex>
  )
}

ShippingPage.pageType = 'shipping'

export default ShippingPage
